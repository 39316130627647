.btn-menu {
    min-height: 38px;
    width: 210px;
    max-width: 210px;
}

.menu-title {
    color: #495057 !important;
    font-size: 1.25rem;
    margin-bottom: 0.75rem;
}

#shop-select-page {
    font-size: 14px;
    font-family: "Inter", "Helvetica Neue", Arial, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

#shop-select-page .fw-bold{
    color: #495057 !important;
    font-weight: 600 !important;
    margin-bottom: 0.25rem;
}

.shop-select-box select {
    min-width: 200px;
    border-radius: .2rem;
    -moz-padding-start: calc(.85rem - 3px);
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-color: #fff;
    background-position: right 0.85rem center;
    background-repeat: no-repeat;
    background-size: 16px 12px;
    border: 1px solid #ced4da;
    border-radius: 0.2rem;
    color: #495057;
    display: block;
    font-size: .875rem;
    font-weight: 400;
    line-height: 1.5;
    padding: 0.3rem 2.55rem 0.3rem 0.85rem;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    width: 100%;
}

.shop-select-container {
    max-width: 1200px !important;
    margin-left: auto;
    margin-right: auto;
    padding-left: var(--bs-gutter-x, .75rem);
    padding-right: var(--bs-gutter-x, .75rem);
    width: 100%;
}