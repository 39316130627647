@charset "UTF-8";

body {
    background-color: #f5f5f5 !important;
    font-family: "Inter", "Helvetica Neue", Arial, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

/* html抽出で抽出されないCSS調整 */
.hAWpOV {
    display: flex;
    flex-direction: column;
    min-height: calc(100vh - 38px);
    min-height: 100vh;
}
.bBGYmJ {
    background: rgb(238, 238, 238);
/*    padding: 10px 0px 200px;*/
    padding: 10px 0px 10px;
    margin-top: auto;
    text-align: center;
    color: rgb(170, 170, 170);
    font-size: 12px;
}

/* パスワード変更 */
.etUzMC {
    display: flex;
    flex-direction: column;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    max-width: 1200px;
    margin: 30px auto;
    padding: 0px 30px;
}
.eyHrxW {
    margin-bottom: 20px;
}
.mxbDB {
    margin: 10px 0px 30px;
}
.bNUkZT {
    display: flex;
    flex-direction: column;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    gap: 10px;
}

@media(min-width:992px) {
    .content {
      padding: 0.75rem 1.25rem;
    }
}

.calc-content table tr td:first-child {
    background: #58adc5;
    border-radius: 10px;
    color: #fff;
    font-size: 18px;
    width: 170px;
}

dl, ol, ul {
    margin-bottom: 0rem;
    margin-top: 0;
}
ol, ul {
    padding-left: 0rem;
}

.ps-br{
    display: block; /* PCを改行して */
}
.sp-br{
    display: none; /* SPの改行を隠す */
}
/* 画面幅が560px以下の時は */
@media screen and (max-width: 767px) {
    .ps-br{
        display: none; /* PCの改行を隠して */
    }
    .sp-br{
    display: block; /* SPを改行する */
    }
}

@media(min-width:576px) {
    .container, .container-sm {
        max-width: 100%;
    }
}
@media(min-width:768px) {
    .container, .container-md, .container-sm {
        max-width: 100%;
    }
}
@media(min-width:992px) {
    .container, .container-lg, .container-md, .container-sm {
      max-width: 960px
    }
}
@media(min-width:1200px) {
    .container, .container-lg, .container-md, .container-sm, .container-xl {
      max-width: 1200px
    }
}

/* 共通 */
.mt-0 {margin-top: 0 !important;}
.mt-1 {margin-top: 0.25rem !important;}
.mt-2 {margin-top: 0.5rem !important;}
.mt-3 {margin-top: 1.0rem !important;}
.mt-4 {margin-top: 1.5rem !important;}
.mt-5 {margin-top: 3.0rem !important;}
.mb-0 {margin-bottom: 0 !important;}
.mb-1 {margin-bottom: 0.25rem !important;}
.mb-2 {margin-bottom: 0.5rem !important;}
.mb-3 {margin-bottom: 1.0rem !important;}
.mb-4 {margin-bottom: 1.5rem !important;}
.mb-5 {margin-bottom: 3.0rem !important;}
.ms-0 {margin-left: 0 !important;}
.ms-1 {margin-left: 0.25rem !important;}
.ms-2 {margin-left: 0.5rem !important;}
.ms-3 {margin-left: 1.0rem !important;}
.ms-4 {margin-left: 1.5rem !important;}
.ms-5 {margin-left: 3.0rem !important;}
.me-0 {margin-right: 0 !important;}
.me-1 {margin-right: 0.25rem !important;}
.me-2 {margin-right: 0.5rem !important;}
.me-3 {margin-right: 1.0rem !important;}
.me-4 {margin-right: 1.5rem !important;}
.me-5 {margin-right: 3.0rem !important;}

.my-0 {margin-top: 0 !important; margin-bottom: 0 !important;}
.my-1 {margin-top: 0.25rem !important; margin-bottom: 0.25rem !important;}
.my-2 {margin-top: 0.5rem !important; margin-bottom: 0.5rem !important;}
.my-3 {margin-top: 1.0rem !important; margin-bottom: 1.0rem !important;}
.my-4 {margin-top: 1.5rem !important; margin-bottom: 1.5rem !important;}
.my-5 {margin-top: 3.0rem !important; margin-bottom: 3.0rem !important;}
.mx-0 {margin-left: 0 !important; margin-right: 0 !important;}
.mx-1 {margin-left: 0.25rem !important; margin-right: 0.25rem !important;}
.mx-2 {margin-left: 0.5rem !important; margin-right: 0.5rem !important;}
.mx-3 {margin-left: 1.0rem !important; margin-right: 1.0rem !important;}
.mx-4 {margin-left: 1.5rem !important; margin-right: 1.5rem !important;}
.mx-5 {margin-left: 3.0rem !important; margin-right: 3.0rem !important;}
.m-1 {margin-top: 0.25rem !important; margin-left: 0.25rem !important; margin-right: 0.25rem !important; margin-bottom: 0.25rem !important;}
.m-2 {margin-top: 0.5rem !important; margin-left: 0.5rem !important; margin-right: 0.5rem !important; margin-bottom: 0.5rem !important;}
.m-3 {margin-top: 1.0rem !important; margin-left: 1.0rem !important; margin-right: 1.0rem !important; margin-bottom: 1.0rem !important;}
.m-4 {margin-top: 1.5rem !important; margin-left: 1.5rem !important; margin-right: 1.5rem !important; margin-bottom: 1.5rem !important;}
.m-5 {margin-top: 3.0rem !important; margin-left: 3.0rem !important; margin-right: 3.0rem !important; margin-bottom: 3.0rem !important;}
.m-0 {margin-top: 0 !important; margin-left: 0 !important; margin-right: 0 !important; margin-bottom: 0 !important;}

.pt-0 {padding-top: 0 !important;}
.pt-1 {padding-top: 0.25rem !important;}
.pt-2 {padding-top: 0.5rem !important;}
.pt-3 {padding-top: 1.0rem !important;}
.pt-4 {padding-top: 1.5rem !important;}
.pt-5 {padding-top: 3.0rem !important;}
.pb-0 {padding-bottom: 0 !important;}
.pb-1 {padding-bottom: 0.25rem !important;}
.pb-2 {padding-bottom: 0.5rem !important;}
.pb-3 {padding-bottom: 1.0rem !important;}
.pb-4 {padding-bottom: 1.5rem !important;}
.pb-5 {padding-bottom: 3.0rem !important;}
.ps-0 {padding-left: 0 !important;}
.ps-1 {padding-left: 0.25rem !important;}
.ps-2 {padding-left: 0.5rem !important;}
.ps-3 {padding-left: 1.0rem !important;}
.ps-4 {padding-left: 1.5rem !important;}
.ps-5 {padding-left: 3.0rem !important;}
.pe-0 {padding-right: 0 !important;}
.pe-1 {padding-right: 0.25rem !important;}
.pe-2 {padding-right: 0.5rem !important;}
.pe-3 {padding-right: 1.0rem !important;}
.pe-4 {padding-right: 1.5rem !important;}
.pe-5 {padding-right: 3.0rem !important;}
.pe-0 {padding-right: 0 !important;}
.p-1 {padding-top: 0.25rem !important; padding-left: 0.25rem !important; padding-right: 0.25rem !important; padding-bottom: 0.25rem !important;}
.p-2 {padding-top: 0.5rem !important; padding-left: 0.5rem !important; padding-right: 0.5rem !important; padding-bottom: 0.5rem !important;}
.p-3 {padding-top: 1.0rem !important; padding-left: 1.0rem !important; padding-right: 1.0rem !important; padding-bottom: 1.0rem !important;}
.p-4 {padding-top: 1.5rem !important; padding-left: 1.5rem !important; padding-right: 1.5rem !important; padding-bottom: 1.5rem !important;}
.p-5 {padding-top: 3.0rem !important; padding-left: 3.0rem !important; padding-right: 3.0rem !important; padding-bottom: 3.0rem !important;}
.p-0 {padding-top: 0 !important; padding-left: 0 !important; padding-right: 0 !important; padding-bottom: 0 !important;}


.py-0 {padding-top: 0 !important; padding-bottom: 0 !important;}
.py-1 {padding-top: 0.25rem !important; padding-bottom: 0.25rem !important;}
.py-2 {padding-top: 0.5rem !important; padding-bottom: 0.5rem !important;}
.py-3 {padding-top: 1.0rem !important; padding-bottom: 1.0rem !important;}
.py-4 {padding-top: 1.5rem !important; padding-bottom: 1.5rem !important;}
.py-5 {padding-top: 3.0rem !important; padding-bottom: 3.0rem !important;}
.px-0 {padding-left: 0 !important; padding-right: 0 !important;}
.px-1 {padding-left: 0.25rem !important; padding-right: 0.25rem !important;}
.px-2 {padding-left: 0.5rem !important; padding-right: 0.5rem !important;}
.px-3 {padding-left: 1.0rem !important; padding-right: 1.0rem !important;}
.px-4 {padding-left: 1.5rem !important; padding-right: 1.5rem !important;}
.px-5 {padding-left: 3.0rem !important; padding-right: 3.0rem !important;}

.gapx-0 {column-gap: 0!important;}
.gapy-1 {row-gap: .25rem!important;}
.gapx-1 {column-gap: .25rem!important;}
.gapy-2 {row-gap: .5rem!important;}
.gapx-2 {column-gap: .5rem!important;}
.gapy-3 {row-gap: 1rem!important;}
.gapx-3 {column-gap: 1rem!important;}
.gapy-4 {row-gap: 1.5rem!important;}
.gapx-4 {column-gap: 1.5rem!important;}
.gapy-5 {row-gap: 3rem!important;}
.gapx-5 {column-gap: 3rem!important;}
.gapy-6 {row-gap: 4.5rem!important;}
.gapx-6 {column-gap: 4.5rem!important;}
.gapy-7 {row-gap: 6rem!important;}
.gapx-7 {column-gap: 6rem!important;}
/*
.g-1 {gap: 0.25rem !important;}
.g-2 {gap: 0.5rem !important;}
.g-3 {gap: 1.0rem !important;}
.g-4 {gap: 1.5rem !important;}
.g-5 {gap: 3.0rem !important;}
*/

.float-left {float:left;}
.float-right {float:right;}
.float-none {float:none;}

.fs-sm {
    font-size: 0.875rem !important;
}
.fs-xs {
    font-size: 0.75rem !important;
}
@media(min-width:576px) {
    .fs-sm-sm {
        font-size: 0.875rem !important;
    }
}
@media(min-width:768px) {
    .fs-md-sm {
        font-size: 0.875rem !important;
    }
}
@media(min-width:992px) {
    .fs-lg-sm {
        font-size: 0.875rem !important;
    }
}
@media(min-width:1200px) {
    .fs-xl-sm {
        font-size: 0.875rem !important;
    }
}


.fc-red {
    color: #D83B3F;
}
.form-select {
    background-position: right 0.55rem center;
}
.form-label-minw {
    min-width: 140px;
  }

.btn-settingicon .fa-1xh {
    font-size: 1.5em;
}


.box-w-sm {
    width: 576px;
    max-width: 100%;
}
.box-w-md {
    width: 768px;
    max-width: 100%;
}
.box-w-lg {
    width: 992px;
    max-width: 100%;
}
.box-w-xl {
    width: 1200px;
    max-width: 100%;
}
.box-w-xxl {
    width: 1400px;
    max-width: 100%;
}


.btn {
    background-color: transparent;
    border: 1px solid transparent;
    border-radius: 0.25rem;
    color: #212529;
    cursor: pointer;
    display: inline-block;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    padding: 0.375rem 0.75rem;
    text-align: center;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    vertical-align: middle;
}

.btn {
    text-decoration: none;
    box-shadow: 0px 1px 6px -3px rgba(0, 0, 0, 0.4);
    outline: none;
}

.btn.disabled {
    pointer-events: none;
}

.btn-noshadow {
    box-shadow: none;
}
.btn-sm {
    padding: 0.25rem 0.5rem;
    font-size: .875rem;
    border-radius: 0.2rem;
}
.btn-xs {
    padding: 0.125rem 0.25rem;
    font-size: 0.625rem;
    border-radius: 0.2rem;
}
.btn-lg {
    padding: 0.5rem 1.25rem;
    font-size: 1.325rem;
    border-radius: 0.4rem;
}

.btn-default, .btn-default:focus {
    color: #333;
    background: #fff;
    border: 1px solid #ccc;
}
.btn-default:hover {
    color: #000;
    background-color: #ddd;
    border-color: #aaa;
}
.btn-default.disabled, .btn-default:disabled {
    color: #333;
    background: #fff;
    border: 1px solid #ccc;
    opacity: 0.4;
}

.btn-primary, .btn-primary:focus {
    color: #fff;
    background: #3d9be8;
    border: 1px solid #3d9be8;
}
.btn-primary:hover {
    color: #fff;
    background-color: #1981d7;
    border-color: #1981d7;
}
.btn-primary.disabled, .btn-primary:disabled {
    color: #fff;
    background: #3d9be8;
    border: 1px solid #3d9be8;
    opacity: 0.5;
}

.btn-secondary, .btn-secondary:focus {
    color: #fff;
    background: #666;
    border: 1px solid #666;
}
.btn-secondary:hover {
    color: #fff;
    background-color: #444;
    border-color: #444;
}
.btn-secondary.disabled, .btn-secondary:disabled {
    color: #fff;
    background: #666;
    border: 1px solid #666;
    opacity: 0.3;
}

.btn-orange, .btn-orange:focus {
    color: #fff;
    background: #ef932a;
    border: 1px solid #ef932a;
}
.btn-orange:hover {
    color: #fff;
    background-color: #e48111;
    border-color: #e48111;
}
.btn-orange.disabled, .btn-orange:disabled {
    color: #fff;
    background: #ef932a;
    border: 1px solid #ef932a;
    opacity: 0.5;
}


.btn-dark, .btn-dark:focus {
    color: #fff;
    background: #2A2A2A;
    border: 1px solid #2A2A2A;
}
.btn-dark:hover {
    color: #fff;
    background-color: #454545;
    border-color: #454545;
}
.btn-dark.disabled, .btn-dark:disabled {
    color: #fff;
/*    background: #2A2A2A;*/
    background: #7f7f7f;
    border: 1px solid #2A2A2A;
    opacity: 0.5;
}

.btn-gray, .btn-gray:focus {
    color: #fff;
    background: #aaa;
    border: 1px solid #aaa;
}
.btn-gray:hover {
    color: #fff;
    background-color: #999;
    border-color: #999;
}
.btn-gray.disabled, .btn-gray:disabled {
    color: #fff;
    background: #aaa;
    border: 1px solid #aaa;
    opacity: 0.5;
}


.btn-pink, .btn-pink:focus {
    color: #fff;
    background: #f679a7;
    border: 1px solid #f679a7;
}
.btn-pink:hover {
    color: #fff;
    background-color: #f3538e;
    border-color: #f3538e;
}
.btn-pink.disabled, .btn-pink:disabled {
    color: #fff;
    background: #f679a7;
    border: 1px solid #f679a7;
    opacity: 0.5;
}

.btn-red, .btn-red:focus {
    color: #fff;
    background: #D83B3F;
    border: 1px solid #D83B3F;
}
.btn-red:hover {
    color: #fff;
    background-color: #B62B30;
    border-color: #B62B30;
}.btn-red.disabled, .btn-red:disabled {
    color: #fff;
    background: #D83B3F;
    border: 1px solid #D83B3F;
    opacity: 0.5;
}



/* btn outline */
.btn-outline-default, .btn-outline-default:focus {
    background-color: #fff;
    border-color: #888;
    color: #888;
}
.btn-outline-default:hover {
    background-color: #888;
    border-color: #888;
    color: #fff;
}
.btn-outline-default.disabled, .btn-default:disabled {
    background-color: #fafafa;
    color: #888;
    opacity: 0.5;
}

.btn-outline-primary, .btn-outline-default:focus {
    background-color: #fff;
    border-color: #3d9be8;
    color: #3d9be8;
}
.btn-outline-primary:hover {
    background-color: #3d9be8;
    border-color: #3d9be8;
    color: #fff;
}
.btn-outline-primary.disabled, .btn-outline-primary:disabled {
    background-color: #fafafa;
    color: #3d9be8;
    opacity: 0.5;
}

.btn-outline-secondary, .btn-outline-secondary:focus {
    background-color: #fff;
    border-color: #808080;
    color: #808080;
}
.btn-outline-secondary:hover {
    background-color: #808080;
    border-color: #808080;
    color: #fff;
}
.btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
    background-color: #fafafa;
    color: #808080;
    opacity: 0.5;
}

.btn-outline-orange, .btn-outline-orange:focus {
    background-color: #fff;
    border-color: #ef932a;
    color: #ef932a;
}
.btn-outline-orange:hover {
    background-color: #ef932a;
    border-color: #ef932a;
    color: #fff;
}
.btn-outline-orange.disabled, .btn-outline-orange:disabled {
    background-color: #fafafa;
    color: #ef932a;
    opacity: 0.5;
}

.btn-outline-dark, .btn-outline-dark:focus {
    background-color: #fff;
    border-color: #2A2A2A;
    color: #2A2A2A;
}
.btn-outline-dark:hover {
    background-color: #2A2A2A;
    border-color: #2A2A2A;
    color: #fff;
}
.btn-outline-dark.disabled, .btn-outline-dark:disabled {
    background-color: #fafafa;
    color: #2A2A2A;
    opacity: 0.5;
}

.btn-outline-gray, .btn-outline-gray:focus {
    background-color: #fff;
    border-color: #aaa;
    color: #aaa;
}
.btn-outline-gray:hover {
    background-color: #aaa;
    border-color: #aaa;
    color: #fff;
}
.btn-outline-gray.disabled, .btn-outline-gray:disabled {
    background-color: #fafafa;
    color: #aaa;
    opacity: 0.5;
}

.btn-outline-pink, .btn-outline-pink:focus {
    background-color: #fff;
    border-color: #f679a7;
    color: #f679a7;
}
.btn-outline-pink:hover {
    background-color: #f679a7;
    border-color: #f679a7;
    color: #fff;
}
.btn-outline-pink.disabled, .btn-outline-pink:disabled {
    background-color: #fafafa;
    color: #ff7bac;
    opacity: 0.5;
}

.btn-outline-red, .btn-outline-red:focus {
    background-color: #fff;
    border-color: #D83B3F;
    color: #D83B3F;
}
.btn-outline-red:hover {
    background-color: #B62B30;
    border-color: #B62B30;
    color: #fff;
}.btn-outline-red.disabled, .btn-outline-red:disabled {
    background-color: #fafafa;
    color: #B62B30;
    opacity: 0.5;
}


/* base btn */
.btn-danger {
    background-color: #dc3545;
    border-color: #dc3545;
    color: #fff;
}







/* modal-btn */
.modal-btn-primary {
    color: #fff;
    background: #44C2EB;
    border: 1px solid #44C2EB;
}
.modal-btn-primary:hover {
    color: #fff;
    background-color: #1AA9D5;
    border-color: #1AA9D5;
}
.modal-btn-primary.disabled, .modal-btn-primary:disabled {
    color: #fff;
    background: #44C2EB;
    border: 1px solid #44C2EB;
    opacity: 0.5;
}

/* header */
header table {
    background: #555;
}
.header-row-top {
    background: #555;
}
.header-row-second {
    background: #f5f5f5;
}

.page-header {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 0px 5px;
    background-color: rgb(56, 56, 56);
    color: rgb(255, 255, 255);
    font-size: 10px;
    height: 24px;
    padding: 0px;
}

/* main */
.content-main {
    display: flex;
    flex-direction: column;
    max-width: 100%;
    margin: 30px auto;
    padding: 0px 30px;
}


/* ショップロゴ */
.head-logo {
/*    background: #555;*/
    padding: 4px;
    height: 60px;
    width: 80px;
    text-align: center;
}
.head-logo img {
    max-height: 100%;
    max-width: 100%;
    height: 54px;
    object-fit: cover;
}
.head-name {
/*    background: #555;*/
    color: #fff;
    font-size: 32px;
    font-weight: 700;
    height: 60px;
    padding: 5px 10px;
    position: relative;
    text-align: left;
    vertical-align: middle;
}
.head-nav {
/*    background: #555;*/
    color: #fff;
    padding: 4px 24px 4px 8px;
    text-align: right;
}
.head-nav-inner {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 4px 0.75rem;
    flex-wrap: wrap;
}
.head-loginuser {
    font-size: 14px;
}

/* 今月の目標設定 */
.shop-desc {
/*    background-color: #f5f5f5; */
    height: auto;
    position: relative;
    padding: 10px 20px;
}

.shop-goal {
    color: #333;
    display: inline-block;
    padding: 0;
    width: 100%;
}
.shop-goal .shop-goal-header {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 0.5rem;
}
.shop-goal .shop-goal-body {
    font-size: 18px;
    font-weight: 700;
    line-height: 1.2;
    overflow-y: auto;
    height: 66px;
}
.shop-goal .shop-goal-body p {
    line-height: 1.2;
    padding-bottom: 0;
}


/* ドロップダウンメニュー */
.btn-group-sm>.btn {
    font-size: .825rem;
}
.dropdown {
    position: relative;
    display: inline-block;
}
.dropdown .btn-drop {
    padding-right: calc(0.75rem + 20px);
    display: flex;
    align-items: center;
}
.dropdown .btn-drop::after {
    position: absolute;
    right: 10px;
    width: 10px;
    height: 7px;
    background-color: #535353;
    clip-path: polygon(0 0, 100% 0, 50% 100%);
    content: '';
    pointer-events: none;
}
.dropdown .btn-drop.btn-drop-white::after {
    background-color: #f1f1f1;
}

/* .dropdown-menu {
    display: none;
    position: absolute;
    background-color: #fff;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 10;
    border-radius: 4px;
    margin-top: 2px;
} */


/* ドロップメニュー */
.dropdown-menu a {
    color: #000;
    padding: 0.825rem 1rem;
    text-decoration: none;
    display: block;
    text-align: left;
    white-space: nowrap;
}
.dropdown-menu li:first-child a {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
}
.dropdown-menu li:last-child a {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
}
.dropdown-menu a:hover {
    background-color: #eee;
}
.dropdown-menu a.active {
    background-color: rgb(58 153 220 / 30%);
/*    color: #1288cc;*/
    font-weight: 700;
}

.dropdown-menu a.disabled, .dropdown-menu a:disabled {
    background: #7f7f7f;
    color: #ffffff;
    cursor: not-allowed;
    opacity: 0.4;
}

/* ドロップメニュー右寄せ */
/* .dropdown-menu.dropdown-menu-right {
    right: 0;
} */
/* ドロップメニュー区切り線 */
.dropdown-menu.dropdown-menu-separate li:not(:last-child) {
    border-bottom: 1px solid #ccc;
}

/* ドロップメニュー表示 */
.dropdown.active .dropdown-menu {
  display: block;
}

/* 設定ボタン */
.btn-settingicon {
    color: #fff;
    background: transparent;
    border: 0;
    font-size: 14px;
    padding: 0.25rem 0.5rem;
    white-space: nowrap;
    box-shadow: none;
}
.btn-settingicon:hover {
    color: #ccc;
}


/* ヘッダーTOP 赤帯 */
.bar-red {
    background: rgb(240, 0, 0);
    padding: 5px;
}

/* login */
.login-box {
    width: 380px;
    max-width: 100%;
}
.version {
    color: #aaa;
    font-size: 0.8rem;
}

/* form関連 */
.form-control::-webkit-input-placeholder{  /* chrome */
	color: #bbb;
}
.form-control:-ms-input-placeholder{  /* ie */
	color: #bbb;
}
.form-control::placeholder{
	color: #bbb;
}

/* input-group-icon */
.input-group-icon .form-control {
    border-right: 0;
    padding-right: 0;
}

.input-group-icon .input-group-text-icon {
    align-items: center;
    background-color: #fff;
    /* border: 1px solid #ced4da; */
    border-left: 0;
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    color: #495057;
    display: flex;
    font-size: .875rem;
    font-weight: 400;
    line-height: 1.5;
    padding: 0.3rem 0.85rem;
    text-align: center;
    white-space: nowrap;
}

/* スタッフリスト条件 */
.stafflist-header {
    display: flex;
    align-items: end;
    gap: 0.5rem;
    flex-wrap: wrap;
}
.stafflist-header-left {
    flex: 1 1 auto;
    display: flex;
    align-items: end;
    gap: 0.5rem;
    flex-wrap: wrap;
}
.stafflist-header-right {
    display: flex;
    align-items: end;
    gap: 0.5rem;
    flex-wrap: wrap;
}
.stafflist-select {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
    flex-wrap: wrap;
}
.stafflist-select .selecttitle {
    font-size: 0.825rem;
    padding-left: 0.25rem;
}
.stafflist-conditions {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    flex-wrap: wrap;
}

/* .selectbox {
    display: inline-flex;
    align-items: center;
    position: relative;
}
.selectbox::after {
    position: absolute;
    right: 10px;
    width: 10px;
    height: 7px;
    background-color: #535353;
    clip-path: polygon(0 0, 100% 0, 50% 100%);
    content: '';
    pointer-events: none;
}
.selectbox select {
    appearance: none;
    min-width: 160px;
    display: inline-block;
    font-weight: 400;
    line-height: 1.5;
    color: #333333;
    padding: 0.375rem calc(0.75rem + 20px) 0.375rem 0.75rem;
    font-size: 0.825rem;
    border: 1px solid #cccccc;
    border-radius: 0.25rem;
    background-color: #fff;
    cursor: pointer;
    box-shadow: inset 0px 1px 6px -4px rgba(0,0,0,0.4);
}
.selectbox select:focus-visible {
    outline: 0;
} */

/* ---- スタッフ一覧 ---- */
/* .content-staff-box {
    padding: 0px 20px 9px;
    background-color: rgb(238, 238, 238);
    width: auto;
}
.content-staff {
    background: #eee;
    height: auto;
    overflow: auto;
    min-height: 220px;
} */

/* アラート列調整 */
.staff-list .staff-alarm {
    left: 224px;
    word-break: normal;
    text-align: left;
    padding-left: 18px;
    padding-right: 2px;
}

/* アラートのマウスオーバー */
.staff-list tr td a:hover {
    color: #333;
}


/* ---- カリキュラム一括設定 ---- */
.tabs {
    border-bottom: 1px solid transparent;
    min-height: 48px;
    display: flex;
    justify-content: space-between;
    align-items:center;
}
.tabs .tabs-group {
    align-self: end;
}
.tabs .tabs-group-right {
    display: flex;
    align-items:center;
    gap: 0.5rem;
}

.tabs .tab-item {
    background-color: #ddd;
    border: 1px solid #ccc;
    border-bottom: 0;
    border-radius: 8px 8px 0 0;
    cursor: pointer;
    display: inline-block;
    padding: 8px 20px;
}
.tabs .tab-item:hover {
    background-color: #ccc;
}
.tabs .tab-item.active {
    background-color: #3a99dc;
    color: #fff;
}

.content-staff-setting .tabs .tab-item.active {
    background-color: #58adc5;
    color: #fff;
}


/* --- その他微調整 --- */
/* modal */
/* .modal h1 {
       background: #00dcc9;
    background: #26dcbc;
    border-radius: 8px;
    flex-grow: 4;
    font-size: 24px;
    margin-bottom: 10px;
    padding: 8px 0;
    text-align: center;
} */

/* .curriculum-selector {
    margin-bottom: 10px;
    margin-top: 10px;
    position: relative;
}
.curriculum-selector button {
    border-radius: 4px;
    font-size: 1.2em;
    width: 100%;
    border: 1px solid #cccccc;
    box-shadow: inset 0px 1px 6px -4px rgba(0,0,0,0.4);
}
.curriculum-selector button:hover {
    cursor: pointer;
} */

/* 第1選択 */
/* .curriculum-selector-dropdown {
    background-color: #fff;
    border: 1px solid #767676;
    border-image: initial;
    border-radius: 0px;
    color: #555;
    display: none;
    left: 0;
    max-height: 40vh;
    min-width: 200px;
    overflow-y: auto;
    position: absolute;
    top: 98%;
} */
/* 第2選択 */
/* .course-selector-group-items {
    background-color: #fff;
    border: 1px solid #767676;
    display: none;
    left: 201px;
    max-height: 40vh;
    min-width: 200px;
    overflow-y: auto;
    position: absolute;
    top: 32px;
    border-radius: 0px;
} */
/* 第3選択 */
/* .curriculum-selector-group-items {
    background-color: #fff;
    border: 1px solid #767676;
    display: none;
    left: 402px;
    max-height: 40vh;
    min-width: 200px;
    overflow-y: auto;
    position: absolute;
    top: 32px;
}
.curriculum-selector-group-item {
    background-color: #fff;
    border-bottom: 1px solid #ccc;
    color: #333;
    padding: 5px;
}
.curriculum-selector-group-name:hover {
    color: #333;
    background-color: #eee;
    cursor: pointer;
}
.curriculum-selector-group-item:hover {
    color: #333;
    background-color: #eee;
    cursor: pointer;
} */


/* 店舗選択 */
.container-shop-select {
    width: auto;
    max-width: 740px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
}
.shop-select-box {
    text-align: left;
    display: inline-block;
}
.shop-select-box .form-select {
    min-width: 200px;
}
/* 店舗選択画面　メニュー */
.menu-title {
    font-size: 1.25rem;
    margin-bottom: 0.75rem;
    text-align: center;
}
@media(min-width:768px) {
    .menu-title {
        text-align: left;
    }    
}
.btn-menu {
    min-height: 38px;
    width: 210px;
    max-width: 210px;
}
.menu-row {
    margin: -8px;
}
.menu-col {
    padding: 8px;
}

/* ページネーション */
.page-item:first-child .page-link {
    border-top-left-radius: 2px;
    border-bottom-left-radius: 2px;
}
.page-item:last-child .page-link {
    border-top-right-radius: 2px;
    border-bottom-right-radius: 2px;
}

.pagination .page-item {
    line-height: 1;
}
.pagination .page-item .page-link {
    color: #495057;
    text-wrap: nowrap;

}
.pagination.fs-sm .page-item .page-link {
    font-size: 0.875rem;
}
.pagination.fs-xs.page-item .page-link {
    font-size: 0.75rem;
}

.pagination .page-item a.page-link:hover {
    color: #495057;
    background-color: #e9ecef;
    border-color: #e9ecef;
}
.pagination .page-item .page-link:focus {
    box-shadow: none;
}
.pagination .page-item.active>.page-link,
.pagination .page-item .page-link.active {
    color: #fff;
    background-color: #3d9be8;
    border-color: #3d9be8;
}
.pagination .page-item.disabled>.page-link, 
.pagination .page-item .page-link.disabled {
    color: #bec2c6;
    pointer-events: none;
    background-color: #fff;
    border-color: #dee2e6;
}
.pagination .page-item.omission>.page-link, 
.pagination .page-item .page-link.omission {
    pointer-events: none;
}

.input-group {
    align-items: stretch;
    display: flex;
    flex-wrap: wrap;
    position: relative;
    width: 100%;
}

/* #login-page .form-control {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-clip: padding-box;
    background-color: #fff;
    border: 1px solid #ced4da;
    border-radius: 0.2rem;
    color: #495057;
    display: block;
    font-size: .875rem;
    font-weight: 400;
    line-height: 1.5;
    padding: 0.3rem 0.5rem;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
} */

.input-group>.form-control, .input-group>.form-select {
    flex: 1 1 auto;
    min-width: 0;
    position: relative;
    width: 1%;
}

#login-page .form-control-lg {
    border-radius: 0.3rem 0 0 0.3rem;
    font-size: .925rem;
    min-height: calc(2.1875rem + 2px);
    padding: 0.4rem 1rem;
}

#login-page .form-control:focus {
    /* color: var(--bs-body-color);
    background-color: var(--bs-body-bg);
    border-color: #86b7fe;
    outline: 0; */
    box-shadow: none;
}

.text-id {
    font-size: 14px;
    margin-bottom: 0.5rem;
    color: #495057;
}

#login-page .card {
    word-wrap: break-word;
    background-clip: border-box;
    background-color: #fff;
    border: 0 solid rgba(0,0,0,.125);
    border-radius: 0.25rem;
    display: -ms-flexbox;
    -ms-flex-direction: column;
    display: flex;
    flex-direction: column;
    min-width: 0;
    position: relative;
    box-shadow: 0 0 1px rgb(0 0 0 / 12%), 0 1px 2px rgb(0 0 0 / 20%);
    margin-bottom: 1rem;
}

#login-page a {
    font-size: 14px;
    color: #3b7ddd;
    text-decoration: none;
}

#login-page h1 {
    font-size: 1.53125rem;
}

.input-group-icon .form-control:focus+span.input-group-text-icon {
    border-color: #86b7fe;
}

/* 認定ボタン */
.btn-stepCert, .btn-stepCert:focus, .btn-stepCert:active,
.btn-trCert, .btn-trCert:focus, .btn-trCert:active {
    color: #fff;
    background: #58adc5;
    border: 2px solid #b4dae4;
    border-radius: 6px;
    padding: 0.2rem 0.5rem;
}
.btn-stepCert:hover,
.btn-trCert:hover {
    color: #fff;
    background-color: #b4dae4;
    border-color: #b4dae4;
}
.btn-stepCert.disabled, .btn-stepCert:disabled,
.btn-trCert.disabled, .btn-trCert:disabled {
    color: #333;
    background-color: #f3f3f3;
    border-color: #f3f3f3;
    opacity: 0.5;
}
.btn-stepCert.cert,
.btn-trCert.cert {
    color: #333;
    background-color: #ffe4c4;
    border-color: #ffe4c4;

}
.btn-stepCert.cert:hover,
.btn-trCert.cert:hover {
    color: #333;
    background-color: #ffedd6;
    border-color: #ffedd6;
}