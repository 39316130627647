@charset "UTF-8";

/* ユーザーエージェントスタイルシートの違いによる調整 */
table {
    line-height: inherit;
    white-space-collapse: inherit;
    text-wrap: inherit;
    font-style: initial;
    font-feature-settings: inherit;
    font-variant: inherit;
}

/* html抽出で抽出されないCSS調整 */
.hAWpOV {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}
.bBGYmJ {
    background: rgb(238, 238, 238);
/*    padding: 10px 0px 200px;*/
    padding: 10px 0px 10px;
    margin-top: auto;
    text-align: center;
    color: rgb(170, 170, 170);
    font-size: 12px;
}

/* パスワード変更 */
.etUzMC {
    display: flex;
    flex-direction: column;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    max-width: 1200px;
    margin: 30px auto;
    padding: 0px 30px;
}
.eyHrxW {
    margin-bottom: 20px;
}
.mxbDB {
    margin: 10px 0px 30px;
}
.bNUkZT {
    display: flex;
    flex-direction: column;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    gap: 10px;
}


/* 共通 */
.mt-0 {margin-top: 0 !important;}
.mt-1 {margin-top: 0.25rem !important;}
.mt-2 {margin-top: 0.5rem !important;}
.mt-3 {margin-top: 1.0rem !important;}
.mt-4 {margin-top: 1.5rem !important;}
.mt-5 {margin-top: 3.0rem !important;}
.mb-0 {margin-bottom: 0 !important;}
.mb-1 {margin-bottom: 0.25rem !important;}
.mb-2 {margin-bottom: 0.5rem !important;}
.mb-3 {margin-bottom: 1.0rem !important;}
.mb-4 {margin-bottom: 1.5rem !important;}
.mb-5 {margin-bottom: 3.0rem !important;}
.ms-0 {margin-left: 0 !important;}
.ms-1 {margin-left: 0.25rem !important;}
.ms-2 {margin-left: 0.5rem !important;}
.ms-3 {margin-left: 1.0rem !important;}
.ms-4 {margin-left: 1.5rem !important;}
.ms-5 {margin-left: 3.0rem !important;}
.me-0 {margin-right: 0 !important;}
.me-1 {margin-right: 0.25rem !important;}
.me-2 {margin-right: 0.5rem !important;}
.me-3 {margin-right: 1.0rem !important;}
.me-4 {margin-right: 1.5rem !important;}
.me-5 {margin-right: 3.0rem !important;}

.my-0 {margin-top: 0 !important; margin-bottom: 0 !important;}
.my-1 {margin-top: 0.25rem !important; margin-bottom: 0.25rem !important;}
.my-2 {margin-top: 0.5rem !important; margin-bottom: 0.5rem !important;}
.my-3 {margin-top: 1.0rem !important; margin-bottom: 1.0rem !important;}
.my-4 {margin-top: 1.5rem !important; margin-bottom: 1.5rem !important;}
.my-5 {margin-top: 3.0rem !important; margin-bottom: 3.0rem !important;}
.mx-0 {margin-left: 0 !important; margin-right: 0 !important;}
.mx-1 {margin-left: 0.25rem !important; margin-right: 0.25rem !important;}
.mx-2 {margin-left: 0.5rem !important; margin-right: 0.5rem !important;}
.mx-3 {margin-left: 1.0rem !important; margin-right: 1.0rem !important;}
.mx-4 {margin-left: 1.5rem !important; margin-right: 1.5rem !important;}
.mx-5 {margin-left: 3.0rem !important; margin-right: 3.0rem !important;}
.m-1 {margin-top: 0.25rem !important; margin-left: 0.25rem !important; margin-right: 0.25rem !important; margin-bottom: 0.25rem !important;}
.m-2 {margin-top: 0.5rem !important; margin-left: 0.5rem !important; margin-right: 0.5rem !important; margin-bottom: 0.5rem !important;}
.m-3 {margin-top: 1.0rem !important; margin-left: 1.0rem !important; margin-right: 1.0rem !important; margin-bottom: 1.0rem !important;}
.m-4 {margin-top: 1.5rem !important; margin-left: 1.5rem !important; margin-right: 1.5rem !important; margin-bottom: 1.5rem !important;}
.m-5 {margin-top: 3.0rem !important; margin-left: 3.0rem !important; margin-right: 3.0rem !important; margin-bottom: 3.0rem !important;}
.m-0 {margin-top: 0 !important; margin-left: 0 !important; margin-right: 0 !important; margin-bottom: 0 !important;}

.pt-0 {padding-top: 0 !important;}
.pt-1 {padding-top: 0.25rem !important;}
.pt-2 {padding-top: 0.5rem !important;}
.pt-3 {padding-top: 1.0rem !important;}
.pt-4 {padding-top: 1.5rem !important;}
.pt-5 {padding-top: 3.0rem !important;}
.pb-0 {padding-bottom: 0 !important;}
.pb-1 {padding-bottom: 0.25rem !important;}
.pb-2 {padding-bottom: 0.5rem !important;}
.pb-3 {padding-bottom: 1.0rem !important;}
.pb-4 {padding-bottom: 1.5rem !important;}
.pb-5 {padding-bottom: 3.0rem !important;}
.ps-0 {padding-left: 0 !important;}
.ps-1 {padding-left: 0.25rem !important;}
.ps-2 {padding-left: 0.5rem !important;}
.ps-3 {padding-left: 1.0rem !important;}
.ps-4 {padding-left: 1.5rem !important;}
.ps-5 {padding-left: 3.0rem !important;}
.pe-0 {padding-right: 0 !important;}
.pe-1 {padding-right: 0.25rem !important;}
.pe-2 {padding-right: 0.5rem !important;}
.pe-3 {padding-right: 1.0rem !important;}
.pe-4 {padding-right: 1.5rem !important;}
.pe-5 {padding-right: 3.0rem !important;}
.pe-0 {padding-right: 0 !important;}
.p-1 {padding-top: 0.25rem !important; padding-left: 0.25rem !important; padding-right: 0.25rem !important; padding-bottom: 0.25rem !important;}
.p-2 {padding-top: 0.5rem !important; padding-left: 0.5rem !important; padding-right: 0.5rem !important; padding-bottom: 0.5rem !important;}
.p-3 {padding-top: 1.0rem !important; padding-left: 1.0rem !important; padding-right: 1.0rem !important; padding-bottom: 1.0rem !important;}
.p-4 {padding-top: 1.5rem !important; padding-left: 1.5rem !important; padding-right: 1.5rem !important; padding-bottom: 1.5rem !important;}
.p-5 {padding-top: 3.0rem !important; padding-left: 3.0rem !important; padding-right: 3.0rem !important; padding-bottom: 3.0rem !important;}
.p-0 {padding-top: 0 !important; padding-left: 0 !important; padding-right: 0 !important; padding-bottom: 0 !important;}


.py-0 {padding-top: 0 !important; padding-bottom: 0 !important;}
.py-1 {padding-top: 0.25rem !important; padding-bottom: 0.25rem !important;}
.py-2 {padding-top: 0.5rem !important; padding-bottom: 0.5rem !important;}
.py-3 {padding-top: 1.0rem !important; padding-bottom: 1.0rem !important;}
.py-4 {padding-top: 1.5rem !important; padding-bottom: 1.5rem !important;}
.py-5 {padding-top: 3.0rem !important; padding-bottom: 3.0rem !important;}
.px-0 {padding-left: 0 !important; padding-right: 0 !important;}
.px-1 {padding-left: 0.25rem !important; padding-right: 0.25rem !important;}
.px-2 {padding-left: 0.5rem !important; padding-right: 0.5rem !important;}
.px-3 {padding-left: 1.0rem !important; padding-right: 1.0rem !important;}
.px-4 {padding-left: 1.5rem !important; padding-right: 1.5rem !important;}
.px-5 {padding-left: 3.0rem !important; padding-right: 3.0rem !important;}

.g-1 {gap: 0.25rem !important;}
.g-2 {gap: 0.5rem !important;}
.g-3 {gap: 1.0rem !important;}
.g-4 {gap: 1.5rem !important;}
.g-5 {gap: 3.0rem !important;}

.float-left {float:left;}
.float-right {float:right;}
.float-none {float:none;}

.btn-settingicon .fa-1xh {
    font-size: 1.5em;
}

.btn {
    display: inline-block;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    text-align: center;
    text-decoration: none;
    vertical-align: middle;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    background-color: transparent;
    border: 1px solid transparent;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    border-radius: 0.25rem;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    box-shadow: 0px 2px 6px -3px rgba(0, 0, 0, 0.4) ;
    outline: none;
}
.btn.disabled {
    pointer-events: none;
}

.btn-noshadow {
    box-shadow: none;
}
.btn-sm {
    padding: 0.25rem 0.5rem;
    font-size: .875rem;
    border-radius: 0.2rem;
}
.btn-xs {
    padding: 0.125rem 0.25rem;
    font-size: 0.625rem;
    border-radius: 0.2rem;
}
.btn-lg {
    padding: 0.5rem 1.25rem;
    font-size: 1.325rem;
    border-radius: 0.4rem;
}

.btn-default {
    color: #333;
    background: #fff;
    border: 1px solid #ccc;
}
.btn-default:hover {
    color: #000;
    background-color: #ddd;
    border-color: #aaa;
}
.btn-default.disabled, .btn-default:disabled {
    color: #333;
    background: #fff;
    border: 1px solid #ccc;
    opacity: 0.4;
}

.btn-primary {
    color: #fff;
    background: #359ED8;
    border: 1px solid #359ED8;
}
.btn-primary:hover {
    color: #fff;
    background-color: #2178A3;
    border-color: #2178A3;
}
.btn-primary.disabled, .btn-primary:disabled {
    color: #fff;
    background: #359ED8;
    border: 1px solid #359ED8;
    opacity: 0.4;
}

.btn-orange {
    color: #fff;
    background: #fa931f;
    border: 1px solid #fa931f;
}
.btn-orange:hover {
    color: #fff;
    background-color: #E57E17;
    border-color: #E57E17;
}
.btn-orange.disabled, .btn-orange:disabled {
    color: #fff;
    background: #fa931f;
    border: 1px solid #fa931f;
    opacity: 0.4;
}

.btn-secondary {
    color: #fff;
    background: #666;
    border: 1px solid #666;
}
.btn-secondary:hover {
    color: #fff;
    background-color: #444;
    border-color: #444;
}
.btn-secondary.disabled, .btn-secondary:disabled {
    color: #fff;
    background: #666;
    border: 1px solid #666;
    opacity: 0.4;
}

.btn-dark {
    color: #fff;
    background: #2A2A2A;
    border: 1px solid #2A2A2A;
}
.btn-dark:hover {
    color: #fff;
    background-color: #454545;
    border-color: #454545;
}
.btn-secondary.disabled, .btn-secondary:disabled {
    color: #fff;
    background: #2A2A2A;
    border: 1px solid #2A2A2A;
    opacity: 0.4;
}

.btn-gray {
    color: #fff;
    background: #aaa;
    border: 1px solid #aaa;
}
.btn-gray:hover {
    color: #fff;
    background-color: #999;
    border-color: #999;
}
.btn-gray.disabled, .btn-gray:disabled {
    color: #fff;
    background: #aaa;
    border: 1px solid #aaa;
    opacity: 0.4;
}


.btn-pink {
    color: #fff;
    background: #ff7bac;
    border: 1px solid #ff7bac;
}
.btn-pink:hover {
    color: #fff;
    background-color: #ff5a92;
    border-color: #ff5a92;
}
.btn-pink.disabled, .btn-pink:disabled {
    color: #fff;
    background: #ff7bac;
    border: 1px solid #ff7bac;
    opacity: 0.4;
}

.btn-red {
    color: #fff;
    background: #D83B3F;
    border: 1px solid #D83B3F;
}
.btn-red:hover {
    color: #fff;
    background-color: #B62B30;
    border-color: #B62B30;
}.btn-red.disabled, .btn-red:disabled {
    color: #fff;
    background: #D83B3F;
    border: 1px solid #D83B3F;
    opacity: 0.4;
}

.modal-btn-primary {
    color: #fff;
    background: #44C2EB;
    border: 1px solid #44C2EB;
}
.modal-btn-primary:hover {
    color: #fff;
    background-color: #1AA9D5;
    border-color: #1AA9D5;
}
.modal-btn-primary.disabled, .modal-btn-primary:disabled {
    color: #fff;
    background: #44C2EB;
    border: 1px solid #44C2EB;
    opacity: 0.4;
}


/* header row */
.header-row-top {
    background: #555;
}
.header-row-second {
    background: #f5f5f5;
}

/* ショップロゴ */
.head-logo {
/*    background: #555;*/
    padding: 4px;
    height: 52px;
    width: 80px;
    text-align: center;
}
.head-logo img {
    max-height: 100%;
    max-width: 100%;
    height: 54px;
    object-fit: cover;
}
.head-name {
/*    background: #555;*/
    color: #fff;
    font-size: 32px;
    font-weight: 700;
    height: 52px;
    padding: 5px 10px;
    position: relative;
    text-align: left;
    vertical-align: middle;
}
.head-nav {
/*    background: #555;*/
    color: #fff;
    padding: 4px 24px 4px 8px;
    text-align: right;
}
.head-nav-inner {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 4px 0.75rem;
    flex-wrap: wrap;
}
.head-loginuser {
    font-size: 14px;
}
/* 今月の目標設定 */
.shop-desc {
/*    background-color: #f5f5f5; */
    height: auto;
    position: relative;
    padding: 10px 20px;
}

.shop-goal {
    color: #333;
    display: inline-block;
    padding: 0;
    width: 100%;
}
.shop-goal .shop-goal-header {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 0.5rem;
}
.shop-goal .shop-goal-body {
    font-size: 18px;
    font-weight: 700;
    line-height: 1.2;
    overflow-y: auto;
    height: 66px;
}
.shop-goal .shop-goal-body p {
    line-height: 1.2;
    padding-bottom: 0;
}


/* ドロップダウンメニュー */
.dropdown {
    position: relative;
    display: inline-block;
}
.dropdown .btn-drop {
    padding-right: calc(0.75rem + 20px);
    display: flex;
    align-items: center;
}
.dropdown .btn-drop::after {
    position: absolute;
    right: 10px;
    width: 10px;
    height: 7px;
    background-color: #535353;
    clip-path: polygon(0 0, 100% 0, 50% 100%);
    content: '';
    pointer-events: none;
}
.dropdown .btn-drop.btn-drop-white::after {
    background-color: #f1f1f1;
}

#dropdown-component .dropdown-menu {
    display: block;
    position: absolute;
    background-color: #fff;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 10;
    border-radius: 4px;
    margin-top: 2px;
    padding: 0;
}

/* ドロップメニュー */
.dropdown-menu a {
    color: #000;
    padding: 0.825rem 1rem;
    text-decoration: none;
    display: block;
    text-align: left;
    white-space: nowrap;
}
.dropdown-menu li:first-child a {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
}
.dropdown-menu li:last-child a {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
}
.dropdown-menu a:hover {
    background-color: #eee;
}
.dropdown-menu a.active {
    background-color: rgb(58 153 220 / 30%);
/*    color: #1288cc;*/
    font-weight: 700;
}

/* ドロップメニュー右寄せ */
.dropdown-menu.dropdown-menu-right {
    right: 0;
}
/* ドロップメニュー区切り線 */
.dropdown-menu.dropdown-menu-separate li:not(:last-child) {
    border-bottom: 1px solid #ccc;
}

/* ドロップメニュー表示 */
.dropdown.active .dropdown-menu {
  display: block;
}

/* 設定ボタン */
.btn-settingicon {
    color: #fff;
    background: transparent;
    border: 0;
    font-size: 14px;
    padding: 0.25rem 0.5rem;
    white-space: nowrap;
    box-shadow: none;
}
.btn-settingicon:hover {
    color: #ccc;
}


/* スタッフリスト条件 */
.stafflist-header {
    display: flex;
    align-items: end;
    gap: 0.5rem;
    flex-wrap: wrap;
    width: 100%;
}
.stafflist-header-left {
    flex: 1 1 auto;
    display: flex;
    align-items: end;
    gap: 0.5rem;
    flex-wrap: wrap;
}
.stafflist-header-right {
    display: flex;
    align-items: end;
    gap: 0.5rem;
    flex-wrap: wrap;
}
.stafflist-select {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
    flex-wrap: wrap;
}
.stafflist-select .selecttitle {
    font-size: 0.825rem;
    padding-left: 0.25rem;
}
.stafflist-conditions {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    flex-wrap: wrap;
}

.selectbox {
    display: inline-flex;
    align-items: center;
    position: relative;
}
.selectbox::after {
    position: absolute;
    right: 10px;
    width: 10px;
    height: 7px;
    background-color: #535353;
    clip-path: polygon(0 0, 100% 0, 50% 100%);
    content: '';
    pointer-events: none;
}
.selectbox select {
    appearance: none;
    min-width: 160px;
    display: inline-block;
    font-weight: 400;
    line-height: 1.5;
    color: #333333;
    padding: 0.375rem calc(0.75rem + 20px) 0.375rem 0.75rem;
    font-size: 0.825rem;
    border: 1px solid #cccccc;
    border-radius: 0.25rem;
    background-color: #fff;
    cursor: pointer;
    box-shadow: inset 0px 1px 6px -4px rgba(0,0,0,0.4);
}
.selectbox select:focus-visible {
    outline: 0;
}

/* ---- スタッフ一覧 ---- */
.content-staff-box {
    background-color: rgb(238, 238, 238);
    width: auto;
}
.bulk-content-staff {
    background: #eee;
    height: calc(100vh - 372px);
    overflow: scroll;
    min-height: 220px;
}

.for-staff {
    height: calc(100vh - 186px) !important;
}

.for-staff-no-button {
    height: calc(100vh - 148px) !important;
}

.content-staff {
    background: #eee;
    height: calc(100vh - 336px);
    min-height: 220px;
}

/* アラート列調整 */
.staff-list .staff-alarm {
    left: 224px;
    word-break: normal;
    text-align: left;
    padding-left: 18px;
    padding-right: 2px;
}

/* アラートのマウスオーバー */
.staff-list tr td a:hover {
    color: #333;
}


/* ---- カリキュラム一括設定 ---- */
.tabs {
    border-bottom: 1px solid transparent;
    min-height: 48px;
    display: flex;
    justify-content: space-between;
    align-items:center;
}
.tabs .tabs-group {
    align-self: end;
}
.tabs .tabs-group-right {
    display: flex;
    align-items:center;
    gap: 0.5rem;
}

.tabs .tab-item {
    background-color: #ddd;
    border: 1px solid #ccc;
    border-bottom: 0;
    border-radius: 8px 8px 0 0;
    cursor: pointer;
    display: inline-block;
    padding: 8px 20px;
}
.tabs .tab-item:hover {
    background-color: #ccc;
}
.tabs .tab-item.active {
    background-color: #58adc5;
    color: #fff;
}

.content-staff-setting .tabs .tab-item.active {
    background-color: #58adc5;
    color: #fff;
}

.staff-list thead tr td {
    background: #3a99dc;
    border: 2px solid #fff;
    color: #fff;
    min-width: 80px;
}

.content-staff-setting .staff-list thead tr td {
    background: #58adc5;
    color: #fff;
}
.content-staff-setting .tabs .tab-item.tab-item-delete.active {
    background-color: #D83B3F !important;
    color: #fff;
}
.tab-item-delete{
    background-color: #D83B3F !important;
    color: #fff;
}

.content-staff-setting .staff-list tr td {
    background: #dbebf0;
    border: 2px solid #fff;
    color: #000;
}

/* --- その他微調整 --- */

.curriculum-selector {
    margin-bottom: 10px;
    margin-top: 10px;
    position: relative;
}
.curriculum-selector button {
    border-radius: 4px;
    font-size: 1.2em;
    width: 100%;
    border: 1px solid #cccccc;
    box-shadow: inset 0px 1px 6px -4px rgba(0,0,0,0.4);
}
.curriculum-selector button:hover {
    cursor: pointer;
}

/* 第1選択 */
.curriculum-selector-dropdown {
    background-color: #fff;
    border: 1px solid #767676;
    border-image: initial;
    border-radius: 0px;
    color: #555;
    display: none;
    left: 0;
    max-height: 40vh;
    min-width: 200px;
    overflow-y: auto;
    position: absolute;
    top: 98%;
}
/* 第2選択 */
.course-selector-group-items {
    background-color: #fff;
    border: 1px solid #767676;
    display: none;
    left: 200px;
    max-height: 40vh;
    min-width: 200px;
    overflow-y: auto;
    position: absolute;
    top: 23px;
    border-radius: 0px;
}
/* 第3選択 */
.curriculum-selector-group-items {
    background-color: #fff;
    border: 1px solid #767676;
    display: none;
    left: 400px;
    max-height: 40vh;
    min-width: 200px;
    overflow-y: auto;
    position: absolute;
    top: 23px;
}
.curriculum-selector-group-item {
    background-color: #fff;
    border-bottom: 1px solid #ccc;
    color: #333;
    padding: 5px;
}
.curriculum-selector-group-name:hover {
    color: #333;
    background-color: #eee;
    cursor: pointer;
}
.curriculum-selector-group-item:hover {
    color: #333;
    background-color: #eee;
    cursor: pointer;
}

.head-report-personal button,
.head-report-personal a {
    padding: 0.375rem 0.8rem;
    margin-right: 4px;
    font-size: 14px;
}

.report-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 0.5rem;
    flex-wrap: wrap;
    margin-bottom: 0.75rem;
}
.report-header-left {
    flex: 1 1 auto;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    flex-wrap: wrap;
}
.report-header-right {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    flex-wrap: wrap;
}
.report-title {
    font-size: 1.325rem;
    padding-left: 0.25rem;
    font-weight: 700;
    color: #495057
}

/* グラフ解析レポート */
.report-analysis-header {
    display: flex;
    align-items: end;
    gap: 0.5rem;
    flex-wrap: wrap;
}

.report-analysis-header-inner {
    flex: 1 1 auto;
    display: flex;
    align-items: end;
    gap: 0.5rem;
    flex-wrap: wrap;
}

.report-analysis-header-inner .row{
    --bs-gutter-y: 0.75rem;
    --bs-gutter-x: 0.75rem;
}

.card-report-analysis {
    margin-bottom: 0;
    height: 100%;
}
.card-report-header {
    display:flex;
    align-items: center;
    justify-content:space-between;
    padding: 0.5rem 1.25rem;
    min-height: 52px;
    flex-wrap: wrap;
    margin-top: 2px;
    background-color: transparent;
}
.card-header .card-report-title {
    margin-bottom: 0;
    font-weight: 700;
    font-size: 1.125rem;
}

.card-report-header .select-box {
    display: inline-flex;
    align-items: center;
    position: relative;
}

.card-report-header .select-box select {
    appearance: none;
    min-width: 160px;
    display: inline-block;
    font-weight: 400;
    line-height: 1.5;
    color: #333333;
    padding: 0.375rem calc(0.75rem + 20px) 0.375rem 0.75rem;
    font-size: 0.825rem;
    border: 1px solid #cccccc;
    border-radius: 0.25rem;
    background-color: #fff;
    cursor: pointer;
    box-shadow: inset 0px 1px 6px -4px rgba(0,0,0,0.4);
}

.card-report-header .select-box .select-title {
    font-size: 0.825rem;
    padding-left: 0.25rem;
}

.card-report-header .select-box-group{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 0.5rem;
}

.content-report{
    padding: 0px 20px 9px;
    background-color: #eee;
    width: auto;
    height: auto;
    min-height: 420px;
}

.content-report-inner{
    height: 100%;
    overflow: unset;
}

/* 履修中カリキュラム進捗率 */
.completion-rate-top-info {
    display: flex;
    justify-content: space-between;
}
.completion-info {
    display: flex;
    flex-direction: column;
}

/* 履修カリキュラム数 */
.completion-curriculum {
    font-size: 1.125rem;
    font-weight: 700;
    color: #1782cf;
}

.completion-text{
    font-size: 0.9rem;
}

.completion-rate-bottom-info {
    display: flex;
    flex-wrap:wrap;
    padding: 0.5rem 1rem;
    background: #deecf8;
}

.chart-box {
    background-color: #f6f6f6;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 10px;
    min-height: 200px;
    height: 100%;
}
@media(max-width:991px) {
    .chart-box {
        height: 0;
    }
}
.chart-box canvas {
    max-width: 100%;
}

.completion-chart {
    flex: 1 1 auto;
}
@media(min-width:992px) {
    .completion-chart {
        width: 45%;
    }
}

.chart-doughnut-box {
/*  background-color: #fff;*/
/*  border: 1px solid #ccc;*/
    border-radius: 4px;
    padding: 2px;
    max-height: 200px;
    max-width: 200px;
    margin: auto;
}

.content-charts {
    direction: ltr;
    flex: 1;
    /*max-width: 100vw;*/
    max-width: 100%;
    padding: 0.75rem 1.5rem;
    width: 100vw;
}

@media(min-width:768px) {
    .content-charts {
        max-width: 100%;
        width: auto;
    }
}

@media(min-width:992px) {
    .content-charts {
        padding: 0.75rem 1.5rem;
    }
}

@media(min-width:992px) {
    .content-charts {
      padding: 0.75rem 1.25rem;
    }
}

@media(min-width:992px) {
    .h-lg-0 {
        height: 0!important;
    }
}

/* レポート表table */
.table-report-list {
    width: 100%;
}
.table-report-list tr td {
    background: #deecf8;
    border: 2px solid #fff;
    color: #333;
    padding: 8px 8px;
    text-align: center;
    vertical-align: middle;
    height: 44px;
    font-size: 0.875rem;
    min-width: 60px;
}

.table-report-list thead tr td {
    background: #3a99dc;
    border: 2px solid #fff;
    color: #fff;
    min-width: 0px;
}
.table-report-list .report-col-no {}
.table-report-list .report-col-name {}

.table-report-list thead .active-row .report-col-rate,
.table-report-list tbody .report-col-rate {
    text-align: end;
}
.table-report-list thead .active-row .report-col-diff,
.table-report-list tbody .report-col-diff {
    text-align: end;
}

/* レポート表table ヘッダー固定 */
.table-header-fixed {
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%;
}
.table-header-fixed .sticky-row,
.table-header-fixed .sticky-row-2,
.table-header-fixed .sticky-row-3 {
    position: sticky;
    top: 0;
    left: 0;
    background: none;
    border-top: none;
    border-bottom: none;
    z-index: 1;
}
.table-header-fixed .sticky-row-2 {
    top: 44px;
}
.table-header-fixed .sticky-row-3 {
    top: 88px;
}

.table-header-fixed .sticky-row:before,
.table-header-fixed .sticky-row-2:before,
.table-header-fixed .sticky-row-3:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
}
.table-header-fixed .sticky-row:before,
.table-header-fixed .sticky-row-2:before {
    background: #3a99dc;
}
.table-header-fixed .sticky-row:before,
.table-header-fixed .sticky-row-2:before,
.table-header-fixed .sticky-row-3:before {
    border-top: 2px solid #fff;
}
.table-header-fixed .sticky-row-1.noBorderTop:before,
.table-header-fixed .sticky-row-2.noBorderTop:before,
.table-header-fixed .sticky-row-3.noBorderTop:before {
    border-top: 0px;
}

/* 固定ヘッダーの自分の順位 */
.table-header-fixed .sticky-row-3:before {
    color: #333;
    background-color: #ffebf1
}
/* レポート表のactive行 */
.table-report-list tr.active-row td {
    color: #333;
    background-color: #ffebf1
}

/* カリキュラム別進捗（表） */
.table-report-list .report-col-progress img {
    max-width: 26px;
    max-height: 26px;
}
.table-report-list tbody td.alert {
    color: #f00;
}

/* カリキュラム別進捗（表）の列項目 */
.report-col-department,
.report-col-progress,
.report-col-goal,
.report-col-remainingperiod,
.report-col-learningperiod {
    width: 15%;
}
.report-col-curriculum {
    width: 25%;
}

/* スクロール領域 */
.scroll-box {
    overflow-x: auto;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    min-height: 624px;
}
@media(max-width:991px) {
    .scroll-box {
        max-height: 480px !important;
    }
}

/* レポートヘッダー */
.report-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 0.5rem;
    flex-wrap: wrap;
    margin-bottom: 0.75rem;
}
.report-header-left {
    flex: 1 1 auto;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    flex-wrap: wrap;
}
.report-header-right {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    flex-wrap: wrap;
}

/* レポートページタイトル */
.report-title {
    font-size: 1.325rem;
    padding-left: 0.25rem;
    font-weight: 700;
}
/* レポート検索条件 */
.report-search-header {
    display: flex;
    align-items: end;
    gap: 0.5rem;
    flex-wrap: wrap;
}
.report-search-header-inner {
    flex: 1 1 auto;
    display: flex;
    align-items: end;
    gap: 0.5rem;
    flex-wrap: wrap;
}
.report-search-select {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
    flex-wrap: wrap;
}
.report-search-select .select-title {
    font-size: 0.825rem;
    padding-left: 0.25rem;
}

.report-select-box {
    display: inline-flex;
    align-items: center;
    position: relative;
}
.report-select-box select {
    appearance: none;
    min-width: 160px;
    display: inline-block;
    font-weight: 400;
    line-height: 1.5;
    color: #333333;
    padding: 0.375rem calc(0.75rem + 20px) 0.375rem 0.75rem;
    font-size: 0.825rem;
    border: 1px solid #cccccc;
    border-radius: 0.25rem;
    background-color: #fff;
    cursor: pointer;
    box-shadow: inset 0px 1px 6px -4px rgba(0,0,0,0.4);
}
.report-select-box select:focus-visible {
    outline: 0;
}

/* レポート検索条件 */
.reportsearch-header {
    display: flex;
    align-items: end;
    gap: 0.5rem;
    flex-wrap: wrap;
}
.reportsearch-header-inner {
    flex: 1 1 auto;
    display: flex;
    align-items: end;
    gap: 0.5rem;
    flex-wrap: wrap;
}
.reportsearch-select {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
    flex-wrap: wrap;
}
.reportsearch-select .selecttitle {
    font-size: 0.825rem;
    padding-left: 0.25rem;
}
.form-switch {
    padding-left: 1.5em;
}
.form-switch .form-check-input {
    background-position: 100%;
}
.form-switch .form-check-input:checked {
    background-position: 0%;
}
.toggle-label-left {
  float: left;
  margin-left: -1.5em;
  font-size: 0.9rem;
  line-height: 1.8;
}
.toggle-label-left + .form-check-input {
  float: left;
  margin-left: 0.5em;
}
.toggle-label-right {
  float: left;
  margin-left: 0.5em;
  font-size: 0.9rem;
  line-height: 1.8;
}
.pointer{
    cursor: pointer;
}
