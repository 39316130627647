@charset "UTF-8";

/*@tailwind base;*/
/*@tailwind components;*/
/*@tailwind utilities;*/

/* Reset */

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    vertical-align: baseline;

}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
    display: block;
}

body {
    line-height: 1;
    font-family: "Lucida Grande", "segoe UI", "ヒラギノ丸ゴ ProN W4", "Hiragino Maru Gothic ProN", Meiryo, Arial, sans-serif;

    background: #F5F5F5;
    color: #231815;
    margin: 0;
    word-break: break-all;
}

ol,
ul {
    list-style: none;


}

blockquote,
q {
    quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
    content: '';
    content: none;
}


body {
    -webkit-text-size-adjust: none;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
}

a {
    text-decoration: none;
    color: #000;
}

i {
    margin: 0 5px;
    /*font-size: 24px !important;*/
}

.top-chart img {
    display: block;
    width: 100%;
    margin: 20px 0;
}



wrapper {
    width: 100%;
}

header {
    width: 100%;
}

header table {
    margin: 0;
    width: 100%;
    border-collapse: collapse;
}

header table tr td {
    vertical-align: middle;
}

.shop-img {
    width: 200px !important;
    height: 160px !important;
    background-position: center center;
}

.shop-name {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 10px;
    color: #fff;
    background: #3e3e3e;
}

.worker-info {
    display: flex;
    align-items: center;
    background: #484848;
    padding: 10px;
}

.shop-info {
    display: flex;
    align-items: center;
}

.shop-name h1 {
    font-size: 70px;
    margin-left: 10px;
    font-weight: normal !important;
}

.shop-name img {
    width: 70px;
}

.login-info {
    display: flex;
    align-items: center;
    padding: 10px;
}

.login-info span {
    background: #484848;
}

/* .login-info button {
    background: #fff;
    color: #000;
    border: 3px solid #7f7f7f;
    border-radius: 7px;
    padding: 1px 20px;
    font-size: 16px;
    margin-left: 10px;
    cursor: pointer;
  } */

.shop-name span {
    margin-right: 10px;
    /* Adiciona um espaçamento entre o texto e o botão */
    padding: 10px;
    /* Adiciona um pouco de padding ao redor do texto */
}


.shop-point {
    position: absolute;
    right: 20px;
    top: 10px;
    text-align: center;
}

.shop-point p {
    text-align: center;
    color: #fff;
    font-size: 32px;
    display: inline-block;
}

.shop-point-num {
    font-size: 32px;
    display: inline-block;
    background: #fff;
    color: #1a1a1a;
    padding: 10px;
    border-radius: 20px;
}

.shop-point-rank {
    font-size: 32px;
    margin-left: 10px;
    display: inline-block;
    background: #fff;
    color: #1a1a1a;
    padding: 10px;
    border-radius: 20px;
}

.shop-point-rank small {
    font-size: 20px;
}

.shop-status {
    padding: 5px 10px;
    height: 60px;
    width: 100px;
    text-align: center;
    font-size: 18px;
    vertical-align: middle;
    line-height: 24px;
    color: #fff;
    background: #063C5E;
}

.shop-desc {
    position: relative;
    height: 80px;
}

.shop-home {
    display: inline-block;
    margin-left: 20px;
}

.shop-home a {
    display: block;
    padding: 30px 10px;
    background: #1abc9c;
    color: #fff;
}

.shop-home a:hover {
    background: #014739;
    color: #fff;
}

.shop-profile {
    background: #ccc;
    display: inline-block;
    margin-left: 20px;
}

.shop-profile a {
    display: block;
    padding: 30px 10px;
    background: #e74b3d;
    color: #fff;
}

.shop-profile a:hover {
    background: #5f0a01;
}

.shop-dream {
    width: 70%;
    display: inline-block;
    color: #1a1a1a;
    font-size: 18px;
    text-align: left;
    vertical-align: middle;
    font-weight: bold;
    padding: 20px;
}

.shop-dream p {
    font-size: 30px;
    text-align: left;
    padding: 5px 0;
}

.shop-dream a {
    position: absolute;
    top: 17px;
    left: 130px;
    background: #3a99ddff;
    font-size: 18px;
    color: #fff;
    text-align: center;
    display: block;
    padding: 2px 20px;
    border-radius: 3px;
    font-weight: bold;
}

.config-container {
    text-align: center;
    margin-left: 20px;
    margin-right: 30px;
    cursor: pointer;
}

.config-icon {
    display: block;
    margin: 0 auto;
}

.config-title {
    margin-top: 5px;
    /* você pode ajustar o espaço entre o SVG e o título */
}

/* .btn{
    background: #fff;
    color: #000;
    padding: 10px 40px;
    border: 2.5px solid #7f7f7f !important;
    border-radius: 10px;
    font-size: 18px;
    border: none;
    cursor: pointer;
    margin-right: 20px;
} */

.btn {
    display: inline-block;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    text-align: center;
    text-decoration: none;
    vertical-align: middle;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    background-color: transparent;
    border: 1px solid transparent;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    border-radius: 0.25rem;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    box-shadow: 0px 2px 6px -3px rgba(0, 0, 0, 0.4);
    outline: none;
}

.btn.disabled {
    pointer-events: none;
}

.btn-noshadow {
    box-shadow: none;
}

.btn-sm {
    padding: 0.25rem 0.5rem;
    font-size: .875rem;
    border-radius: 0.2rem;
}

.btn-xs {
    padding: 0.125rem 0.25rem;
    font-size: 0.625rem;
    border-radius: 0.2rem;
}

.btn-lg {
    padding: 0.5rem 1.25rem;
    font-size: 1.325rem;
    border-radius: 0.4rem;
}

.btn-default {
    color: #333;
    background: #fff;
    border: 1px solid #ccc;
}

.btn-default:hover {
    color: #000;
    background-color: #ddd;
    border-color: #aaa;
}

.btn-default.disabled,
.btn-default:disabled {
    color: #333;
    background: #fff;
    border: 1px solid #ccc;
    opacity: 0.4;
}

.btn-primary {
    color: #fff;
    background: #359ED8;
    border: 1px solid #359ED8;
}

.btn-primary:hover {
    color: #fff;
    background-color: #2178A3;
    border-color: #2178A3;
}

.btn-primary.disabled,
.btn-primary:disabled {
    color: #fff;
    background: #359ED8;
    border: 1px solid #359ED8;
    opacity: 0.4;
}

.btn-secondary {
    color: #fff;
    background: #666;
    border: 1px solid #666;
}

.btn-secondary:hover {
    color: #fff;
    background-color: #444;
    border-color: #444;
}

.btn-secondary.disabled,
.btn-secondary:disabled {
    color: #fff;
    background: #666;
    border: 1px solid #666;
    opacity: 0.4;
}

.btn-dark {
    color: #fff;
    background: #2A2A2A;
    border: 1px solid #2A2A2A;
}

.btn-dark:hover {
    color: #fff;
    background-color: #454545;
    border-color: #454545;
}

.btn-secondary.disabled,
.btn-secondary:disabled {
    color: #fff;
    background: #2A2A2A;
    border: 1px solid #2A2A2A;
    opacity: 0.4;
}

.btn-gray {
    color: #fff;
    background: #aaa;
    border: 1px solid #aaa;
}

.btn-gray:hover {
    color: #fff;
    background-color: #999;
    border-color: #999;
}

.btn-gray.disabled,
.btn-gray:disabled {
    color: #fff;
    background: #aaa;
    border: 1px solid #aaa;
    opacity: 0.4;
}


.btn-pink {
    color: #fff;
    background: #ff7bac;
    border: 1px solid #ff7bac;
}

.btn-pink:hover {
    color: #fff;
    background-color: #ff5a92;
    border-color: #ff5a92;
}

.btn-pink.disabled,
.btn-pink:disabled {
    color: #fff;
    background: #ff7bac;
    border: 1px solid #ff7bac;
    opacity: 0.4;
}

.btn-red {
    color: #fff;
    background: #D83B3F;
    border: 1px solid #D83B3F;
}

.btn-red:hover {
    color: #fff;
    background-color: #B62B30;
    border-color: #B62B30;
}

.btn-red.disabled,
.btn-red:disabled {
    color: #fff;
    background: #D83B3F;
    border: 1px solid #D83B3F;
    opacity: 0.4;
}

.head-name {
    color: #fff;
    font-size: 32px;
    font-weight: 700;
    height: 60px;
    padding: 5px 10px;
    position: relative;
    text-align: left;
    vertical-align: middle;
}


/* .dropdown-button {
    background: #fff;
    color: #000;
    padding: 10px 40px;
    border: 2.5px solid #7f7f7f !important;
    border-radius: 10px;
    font-size: 18px;
    border: none;
    cursor: pointer;
    margin-right: 20px;
}
  
.dropdown-menu {
    position: absolute;
    min-width: 160px;
    z-index: 1;
    background-color: #f9f9f9;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    border: 2.5px solid #7f7f7f !important;
    margin-top: 10px;
}

.dropdown-menu a {
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    color: black;
}

.dropdown-menu a:hover {
    background-color: #f1f1f1;
} */

.content {
    padding: 10px 20px;
    background: #eee;
}

.content-staff {
    background: #EEEEEE;
    height: 400px;
    overflow: scroll;
}

.header-nav {
    padding: 10px 0;
    position: relative;
}

.header-nav ul li {
    display: inline-block;
    margin: 0 10px;
}

.header-nav ul li a {
    padding: 15px;
    border-radius: 5px;
    color: #fff;
}

.header-nav ul li:first-child a {
    background: #7AC943;
}

.header-nav ul li:last-child a {
    background: #FF7BAC;
}

.staff-add {
    padding: 15px;
    border-radius: 5px;
    color: #fff;
    background: #aaa;
    position: absolute;
    right: 10px;
    top: 10px;
}

.calc-add {
    padding: 15px;
    border-radius: 5px;
    color: #fff;
    background: #ff0000;
    position: absolute;
    right: 200px;
    top: 10px;
}

.header-nav a:hover {
    opacity: 0.8;
}

.staff-number {
    margin-top: 20px;
}

.staff-number table {
    border-collapse: collapse;
    width: 100%;
}

.staff-number table tr td {
    vertical-align: middle;
    text-align: center;
    padding: 15px 0;
    border: 1px solid #ccc;
    background: #fff;
}

.staff-number table tr td.all-number {
    background: #C9BB06;
    color: #fff;
    padding: 15px;
}

.staff-number table tr td.all-number p {
    background: #fff;
    color: #C9BB06;
    padding: 15px;
    margin-top: 10px;
    border-radius: 5px;
    font-size: 24px;
    font-weight: bold;
}

.staff-number table tr td strong {
    font-size: 24px;
}

.calc-content {
    background: #fff;
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 10px;
}

.calc-content table tr td {
    vertical-align: middle;
    padding: 10px;
}

.calc-content table tr td:first-child {
    width: 150px;
    font-size: 18px;
    background: #58ADC5;
    color: #fff;
    border-radius: 10px;
}

.calc-list li {
    display: inline-block;
    margin: 10px 5px;
    width: 100px;
}

.calc-list li img {
    display: block;
    width: 100%;
}

.calc-list li:hover img {
    opacity: 0.8;
}

input {}

textarea {
    display: block;
    font-size: 100%;
    width: 100%;
    border-radius: 10px;
    margin: 10px 0;
}

textarea:disabled {
    background-color: #f8eec7;
}

.checklist {
    text-align: left;
    padding: 10px 0;
    font-size: 12px;
}

.checklist input {
    width: 20px;
}

.submitok div input {
    background: #ff0000 !important;
}

div.okng {
    text-align: center;
    margin-top: 10px;
}

button.ok {
    display: inline-block;
    width: 200px;
    background: #d72144;
    color: #fff;
    padding: 10px 0;
    border: none;
    border-radius: 10px;
}

button.ng {
    display: inline-block;
    width: 200px;
    background: #ffc000;
    color: #fff;
    padding: 10px 0;
    border: none;
    border-radius: 10px;
}

a.manual {
    width: 100%;
    display: block;
    padding: 50px 0;
    background: #58adc5;
    color: #fff;
    border-radius: 10px;
}


.staff-list {
    width: 100%;
    caption-side: top !important;
    border-collapse: unset !important;
}

.staff-list * {
    box-sizing: content-box;
}

.staff-list thead {
    position: sticky;
    top: 0;
    z-index: 1;
    height: auto;
}

.staff-list thead tr td {
    border: 2px solid #fff;
    background: #3a99dc;
    color: #fff;
    min-width: 150px;
}

.curriculum-name {
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
}

.curriculum-name:hover {
    background-color: #67caf8;
    color: #fff;
}

.worker-name {
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
}

.worker-name:hover {
    background-color: #67caf8;
    color: #fff;
}

.staff-list tr td {
    border: 2px solid #fff;
    padding: 10px;
    color: #000;
    vertical-align: middle;
    text-align: center;
    background: #deecf8;
}

.staff-list td#staff-name {
    text-align: left !important;
    word-break: keep-all;
    position: sticky;
    left: 0;
    min-width: 320px;
    max-width: 320px;
}

.staff-list td#label-brand {
    white-space: nowrap;
    word-break: keep-all;
    left: 347px;
    position: sticky;
    min-width: 50px;
    max-width: 50px;
}

.staff-list .staff-alarm {
    word-break: normal;
    position: sticky;
    left: 224px;
    min-width: 50px;
    max-width: 50px;
}

.staff-list .calendar-alert {
    word-break: normal;
    position: sticky;
    left: 298px;
    min-width: 25px;
    max-width: 25px;
}

.staff-list .staff-supporter {
    white-space: nowrap;
    word-break: keep-all;
    left: 347px;
    position: sticky;
    min-width: 50px;
    max-width: 50px;
}

.staff-list .staff-goal {
    text-align: left;
    height: 40px !important;
    overflow-y: scroll;
    transform: translateZ(0);
}

.staff-list-step {
    text-align: left;
    width: auto;
    padding: 0;
    background-color: #FFF;
}

.staff-list tr td a {
    display: block;
    color: #000;
}

.staff-list .staff-name {
    text-align: left;
    word-break: keep-all;
    position: sticky;
    left: 0;
    min-width: 200px;
    max-width: 200px;
}

.curriculum-header{
    white-space: nowrap;
    word-break: keep-all;
    left: 420px;
    position: sticky;
    min-width: 50px;
    max-width: 50px;
}

.staff-list tr td.is-me:first-child {
    background: #67caf8;
    color: #fff;
}

.staff-list tr td:first-child:hover a {
    color: #fff;
}

.staff-list tr td a:hover {
    color: #ccc;
}

.staff-list2 {
    width: 100%;
}

.staff-list2 tr td {
    width: 10%;
    border: 1px solid #67caf8;
    padding: 10px;
    color: #67caf8;
    vertical-align: middle;
    text-align: center;
    background: #fff;
}

.staff-list2 tr td a {
    display: block;
    color: #67caf8;
}

.staff-list2 tr td a:hover {
    color: #ccc;
}

.staff-list3 {
    width: 100%;
}

.staff-list3 tr:first-child td {
    width: 10%;
    border: 1px solid #fff;
    padding: 10px;
    color: #fff;
    vertical-align: middle;
    text-align: center;
    background: #47a63d;
}

.staff-list3 tr td a {
    display: block;
    color: #67caf8;
}

.staff-list3 tr td a:hover {
    color: #ccc;
}

.staff-list4 {
    width: 100%;
}

.staff-list4 tr td {
    width: 10%;
    border: 1px solid #ccc;
    background: #def3da;
    padding: 10px;
    color: #111;
    vertical-align: middle;
    text-align: center;
}

.staff-list4 tr td a {
    display: block;
    color: #111;
}

.staff-list4 tr td a:hover {
    color: #fff;
    background: #47a63d;
}

.staff-list .alert-red {
    background: #fe8a8a;
}

.staff-list .light-yellow {
    background: #fff3cc;
}

.staff-list .light-red {
    background: #ffebf1;
}

.staff-list .light-green {
    background: #e3efda;
}

.staff-list .can-trainer {
    background: #FFE4C4;
}

.list-point {
    background: #fff;
    border-radius: 10px;
    text-align: center;
    padding: 10px 0;
}


.slider div img {
    display: block;
    width: 100%;
}

.slider-container {
    position: relative;
}

.shop-manual {
    vertical-align: middle;
    text-align: center;
    background: #d73b43;
}

.shop-manual a {
    background: #d73b43;
    color: #fff;
    display: block;
    padding: 15px 15px;
    font-size: 42px;
}

div.point-pop-wrap {
    width: 214px !important;
    clear: both !important;
    font-size: 12px !important;
    border-radius: 5px !important;
    text-align: left !important;
    padding: 10px !important;
    background: #fff !important;
    position: absolute !important;
    left: 100px !important;
    margin-top: -60px !important;
    z-index: 50 !important;
    min-height: 90px;
}

.point-pop-wrap:before {
    content: "";
    position: absolute;
    top: 50px;
    left: -30px;
    margin-top: -15px;
    border: 15px solid transparent;
    border-right: 15px solid #fff;
    z-index: 200;
}

.point-pop {
    width: auto;
}

.point-pop li:first-child {
    display: inline-block;
    width: auto;
    padding-right: 20px;
}

.point-pop li:last-child {
    display: inline-block;
    width: 30px;
}

/* lily */
.lity-content h1 {
    font-size: 24px;
    padding-bottom: 20px;
}

.mypage-task-button {
    position: relative;
    padding: 0 10px;
    display: inline-block;
}

.image-time-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    color: #696969;
}

.image-time-container span {
    margin-top: 5px;
}

.mypage-task-title {
    position: relative;
    padding: 0px;
    display: block;
    width: 100px;
    text-align: center;
    color: #696969;
    bottom: 7px;
    left: 0px;
    font-size: 12px;
}

.mypage-task-time {
    position: absolute;
    display: block;
    width: 100px;
    text-align: center;
    color: #696969;
    bottom: 3px;
    left: 0px;
    font-size: 15px;
}

.popup-description {
    padding: 10px 0;
}

/* count */
.icon-item {
    position: relative;
}

.count-num {
    position: absolute;
    left: 90px;
    top: 15px;
    color: #948f8f;
    font-size: 20px;
    font-weight: bold;
}

.icon-item:hover .count-num {
    color: #999;
}


/*profile*/
.mypage-cont {
    background: #fff;
    padding: 20px 2%;
    width: 26%;
    margin: 20px 1%;
    height: 50px;
    border-radius: 10px;
    display: inline-block;
    vertical-align: top;
}

.mypage-cont2 {
    background: #fff;
    padding: 20px 2%;
    width: 94%;
    margin: 20px 1%;
    height: 50px;
    border-radius: 10px;
    display: inline-block;
    vertical-align: top;
}

.mypage-cont p {
    padding: 10px 10px;
    font-size: 24px;
}

.mypage-cont2 p {
    padding: 10px 10px;
    font-size: 24px;
}

.copyright {
    background: #eee;
    padding: 10px 0 200px;
    margin-top: 100px;
}

.copyright p {
    text-align: center;
    color: #aaa;
    font-size: 12px;
}

.modal {
    background-color: rgb(56, 70, 83);
    color: #FFF;
    padding: 30px 20px;
    border-radius: 10px;
    position: relative;
    text-align: left;
    vertical-align: middle;
    display: inline-block;
    white-space: normal;
    min-width: 600px;
    max-width: 90vw;
    height: auto;
    outline: none !important;
    overflow-x: unset;
    overflow-y: unset;
}

@media screen and (max-width: 640px) {
    .modal {
        min-width: 90vw;
    }
}

.modal .modal-header {
    display: flex;
    margin-top: -10px;
    border: none;
    padding: 0;
}

.modal .modal-header>h1 {
    text-align: center;
    background: #00dcc9;
    padding: 8px 0;
    border-radius: 10px;
    margin-bottom: 10px;
    font-size: 24px;
    flex-grow: 4;
    font-weight: 700;
    line-height: 1;
}

.modal .modal-close {
    margin-top: -22px;
    margin-right: -12px;
    margin-bottom: 10px;
    text-align: right;
}

.modal .modal-close-button {
    background-color: transparent;
    border: 0px;
    cursor: pointer;
    padding: 0px;
}

.modal .modal-close-icon {
    width: 25px;
    height: auto;
}

#root {
    min-height: 100vh;
}

/*div.submit input{padding:10px 20px;background:#00c9f3;color:#fff;border-radius:10px;margin-top:10px;font-size:30px;}*/
/*button{padding:10px 20px;background:#00c9f3;color:#fff;border-radius:10px;margin-top:10px;font-size:30px;}*/

div.submitok div input {
    background: #ff0000 !important;
}

.slick-prev:before,
.slick-next:before {
    font-size: 48px !important;
}

.slick-prev {
    left: -54px !important;
}

.error-message {
    color: #ff0000;
    font-size: 12px;
    margin-top: 10px;
}

.catselect {
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 8px;
    font-size: medium;
    border-radius: 8px;
}

.width96 {
    width: 96%;
}

.curriculum-selector {
    margin-top: 10px;
    margin-bottom: 10px;
    position: relative;
}

.curriculum-selector button {
    border-radius: 5px;
    font-size: 1.2em;
    width: 100%;
}

.curriculum-selector-dropdown.open {
    display: block;
}

.curriculum-selector-dropdown {
    display: none;
    border-radius: 5px;
    background-color: #fff;
    color: #555;

    border-width: 1px;
    border-style: solid;
    border-color: -internal-light-dark(rgb(118, 118, 118), rgb(133, 133, 133));
    border-image: initial;

    left: 0;
    position: absolute;
    top: 98%;
    min-width: 200px;

    max-height: 40vh;
    overflow-y: auto;
}

.curriculum-selector-group {
    padding: 0px 10px;
    position: relative;
}

/* .curriculum-selector-group:last-child  {
    border-bottom: 0px;
} */

.curriculum-selector-group-name {
    padding: 5px 5px;
    margin: 0px -10px;
    border-bottom: 1px solid #ccc;
}

.curriculum-selector-group-icon {
    float: right;
}

.curriculum-selector-group-name:hover {
    background-color: #ccc;
    cursor: pointer;
}

.curriculum-selector-group-name.selected {
    background-color: #3a99dc;
    color: #fff;
}

.curriculum-selector-group-items {
    border: 1px solid #555;
    display: none;
    position: absolute;
    left: 402px;
    top: 32px;
    min-width: 200px;
    max-height: 40vh;
    overflow-y: auto;
}

.curriculum-selector-group-items.open {
    display: block;
    /* transition: all 0.5s linear; */
}

.course-selector-group-items {
    border: 1px solid #555;
    display: none;
    position: absolute;
    left: 200px;
    top: 32px;
    min-width: 200px;
    max-height: 40vh;
    overflow-y: auto;
}

.course-selector-group-items.open {
    display: block;
    /* transition: all 0.5s linear; */
}

.curriculum-selector-group-item {
    color: #333;
    background-color: #eee;
    border-bottom: 1px solid #ccc;
    padding: 8px;
}

.curriculum-selector-group-item:hover {
    background-color: #ccc;
    cursor: pointer;
}

.curriculum-selector-group-item.selected {
    background-color: #3a99dc;
    color: #fff;
    cursor: pointer;
}

.curriculum-selector-group-item:last-child {
    border-bottom: 0px;
}

.tabs {
    border-bottom: 1px solid #ccc;
}

.tabs .tab-item {
    display: inline-block;
    padding: 10px 20px;
    border: 1px solid #ccc;
    border-bottom: 0px;
    border-radius: 15px 15px 0px 0px;
    cursor: pointer;
}

.tabs .tab-item.active {
    background-color: #3a99dc;
    color: #fff;
}

.tab-button {
    font-size: 1.1em;
    float: right;
    padding: 4px 20px;
    border: 1px solid #999;
    border-radius: 5px;
    background-color: #aaa;
    margin: 0px;
    cursor: pointer;
    color: #fff;
}

.tab-button:hover {
    background-color: #ccc;
}

.self-check-modal-confirm-buttons {
    height: 153px;
    background-color: #595959;
    margin: 20px -20px -30px;
    border-radius: 0 0 10px 10px;
}

.self-check-modal-confirm-buttons>div {
    display: flex;
}

.self-check-modal-confirm-buttons> :first-child {
    border-bottom: 1px solid;
    border-width: 3px;
    height: 70px;
}

.self-check-modal-confirm-buttons>div> :first-child {
    font-size: 26px;
    font-weight: bold;
    display: flex;
    align-items: center;
    margin-left: 20px;
}

.self-check-modal-confirm-buttons>div> :last-child {
    margin: 10px 20px 0 auto;
}

.self-check-modal-confirm-buttons>div> :last-child>button {
    width: 120px;
}

.self-check-modal-confirm-buttons>div> :last-child> :last-child {
    margin-left: 10px;
}

/* @media screen and (min-height: 600px) {
    .selfchecktable-box {
        max-height: 30vh;
    }
} */

.allselect-box {
    display: flex;
    justify-content: end;
    align-items: center;
    padding: 4px 22px;
}

.count-text {
    font-size: 14px;
    padding-right: 1rem;
}

.selfchecktable-box {
    max-height: 140px;
    overflow-y: auto;
    border: 1px solid #aaa;
    border-radius: 4px;
    background-color: #242c35;
}

/*スクロールバー表示させる*/
.selfchecktable-box::-webkit-scrollbar {
    width: 15px;
}

.selfchecktable-box::-webkit-scrollbar-thumb {
    background: #c1c1c1;
    border-radius: 2px;
}

/* 矢印 */
.selfchecktable-box::-webkit-scrollbar-button {
    border-bottom: 5px solid #f1f1f1;
}

.selfchecktable-box::-webkit-scrollbar-button:single-button {
    background-color: #f1f1f1;
    display: block;
    border-style: solid;
    height: 13px;
    width: 16px;

}

/* Up */
.selfchecktable-box::-webkit-scrollbar-button:single-button:vertical:decrement {
    border-top: 8px solid transparent;
    border-right: 8px solid transparent;
    border-bottom: 8px solid #555555;
    border-left: 8px solid transparent;
}

.selfchecktable-box::-webkit-scrollbar-button:single-button:vertical:decrement:hover {
    border-color: transparent transparent #777777 transparent;
}

/* Down */
.selfchecktable-box::-webkit-scrollbar-button:single-button:vertical:increment {
    border-top: 8px solid #555555;
    border-right: 8px solid transparent;
    border-bottom: 8px solid transparent;
    border-left: 8px solid transparent;
}

.selfchecktable-box::-webkit-scrollbar-button:vertical:single-button:increment:hover {
    border-color: #777777 transparent transparent transparent;
}

.selfchecktable-box::-webkit-scrollbar-track-piece:start {
    background: #f1f1f1;
}

.selfchecktable-box::-webkit-scrollbar-track-piece:end {
    background: #f1f1f1;
}

.selfchecktable {
    color: #FFF;
}

.selfchecktable tr td:first-child {
    font-size: 14px;
    width: 510px;
    font-weight: normal;
    line-height: 1.2;
    padding-left: 8px;
}

.selfchecktable input[type=checkbox] {
    transform: scale(1.05);
    margin-bottom: 5px;
    font-size: 16px;
    margin-left: 20px;
}

.preformatted-text {
    max-height: 65px;
    white-space: pre-wrap;
    font-family: "Lucida Grande", "segoe UI", "ヒラギノ丸ゴ ProN W4", "Hiragino Maru Gothic ProN", Meiryo, Arial, sans-serif;
    overflow-wrap: break-word;
    overflow: auto;
    margin-bottom: 10px;
    border: 1px solid #aaa;
    border-radius: 4px;
    background-color: #242c35;
    padding: 0px 8px;
}

.preformatted-text>h1 {
    font-size: 26px;
    font-weight: bold;
    margin: 0;
    padding: 0;
}

.preformatted-text>h2 {
    font-size: 24px;
    font-weight: bold;
    margin: 0;
    padding: 0;
}

.preformatted-text>h3 {
    font-size: 22px;
    font-weight: bold;
    margin: 0;
    padding: 0;
}

.preformatted-text>h4 {
    font-size: 20px;
    font-weight: bold;
    margin: 0;
    padding: 0;
}

.preformatted-text>h5 {
    font-size: 18px;
    font-weight: bold;
    margin: 0;
    padding: 0;
}

.preformatted-text>h6 {
    font-size: 16px;
    font-weight: bold;
    margin: 0;
    padding: 0;
}

/*スクロールバー表示させる*/
.preformatted-text::-webkit-scrollbar {
    width: 15px;
}

.preformatted-text::-webkit-scrollbar-thumb {
    background: #c1c1c1;
    border-radius: 2px;
}

/* 矢印 */
.preformatted-text::-webkit-scrollbar-button {
    border-bottom: 5px solid #f1f1f1;
}

.preformatted-text::-webkit-scrollbar-button:single-button {
    background-color: #f1f1f1;
    display: block;
    border-style: solid;
    height: 13px;
    width: 16px;

}

/* Up */
.preformatted-text::-webkit-scrollbar-button:single-button:vertical:decrement {
    border-top: 8px solid transparent;
    border-right: 8px solid transparent;
    border-bottom: 8px solid #555555;
    border-left: 8px solid transparent;
}

.preformatted-text::-webkit-scrollbar-button:single-button:vertical:decrement:hover {
    border-color: transparent transparent #777777 transparent;
}

/* Down */
.preformatted-text::-webkit-scrollbar-button:single-button:vertical:increment {
    border-top: 8px solid #555555;
    border-right: 8px solid transparent;
    border-bottom: 8px solid transparent;
    border-left: 8px solid transparent;
}

.preformatted-text::-webkit-scrollbar-button:vertical:single-button:increment:hover {
    border-color: #777777 transparent transparent transparent;
}

.preformatted-text::-webkit-scrollbar-track-piece:start {
    background: #f1f1f1;
}

.preformatted-text::-webkit-scrollbar-track-piece:end {
    background: #f1f1f1;
}

/* ユーザー管理一覧 */
.form-search-filter {
    width: 150px;
    max-width: 100%;
}

.table-users-list .table {
    background-color: #fff;
}

.table-users-list .table>:not(:first-child) {
    border-top: 2px solid;
    border-top-color: #1981d7;
}

.table-users-list .table thead tr th {
    color: white !important;
    background: rgb(61, 155, 232) !important;
    padding: 7px 12px;
    border-right: 1px solid #dee2e6;
}

.table-users-list .table>:not(caption)>*>* {
    padding: 0.25rem 0.75rem;
}

.table-users-list .table .dropdown-menu {
    min-width: 120px;
}

.table-users-list .table .dropdown-menu a {
    padding: 8px 12px;
}

.table-users-list tbody,
td,
tfoot,
th,
thead,
tr {
    padding: 4px 12px;
    border: 0 solid;
    border-color: inherit;
}

.table-users-list td {
    color: #495057;
}

#colorSettingModalTable td {
    height: 30px;
}